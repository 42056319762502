import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import TrainingGold from "assets/img/svg/training-gold.svg";
import Img from "gatsby-image";

export const useImage = () => {
  const query = useStaticQuery(graphql`
      query {
          aboutus: file(relativePath: { eq: "img/text-image_2.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `);
  return query;
};

export const useData = () => {
  const query = useImage();
  const data = {
    icon: TrainingGold,
    title: <><span>Wirtualny Asystent</span> to&nbsp;rozwiązanie, które pomoże Ci stać się inwestorem!</>,
    text1: <>Poznaj narzędzie, dzięki któremu zdobędziesz niezbędne informacje i umiejętności potrzebne do inwestowania. <span>Wirtualny Asystent</span> to system, który bada kilka rynków jednocześnie, stale monitoruje zachodzące na nich zmiany oraz wysyła powiadomienia, które pozwolą Ci być na bieżąco!
      <br/> <br/>
      Aplikacja dostępna jest na okres jednego lub trzech miesięcy. </>,
    buttonText: "Sprawdź",
    image: <Img fluid={query.aboutus.childImageSharp.fluid}/>,
    slug: "#form",
  };
  return data;
};

export default useData;
