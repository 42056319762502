import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import TrainingGold from "assets/img/svg/training-gold.svg";
import Img from "gatsby-image";

export const useImage = () => {
  const query = useStaticQuery(graphql`
      query {
          aboutus: file(relativePath: { eq: "img/text-image_3.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `);
  return query;
};

export const useData = () => {
  const query = useImage();
  const data = {
    icon: TrainingGold,
    title: <><span>Wirtualny Asystent PRO</span></>,
    text1: <><span>Wersja PRO</span> to rozbudowany algorytm, który posiada wszystkie możliwości z wersji podstawowej. Jednak jego dodatkowym atutem jest prowadzenie założonej pozycji, możliwość automatycznego dokładania pozycji oraz zabezpieczania ich według ustalonych wcześniej zależności użytkownika.<br/><br/>
      Korzystaj z <span>Wirtualnego Asystenta PRO</span> przez jeden lub trzy miesiące! </>,
    buttonText: "Sprawdź",
    image: <Img fluid={query.aboutus.childImageSharp.fluid}/>,
    slug: "#form",
  };
  return data;
};

export default useData;
