import React from "react";
import data from "data/Hero";
import {GlobalWrapper} from "shared/Styles/Wrappers";
import {graphql, useStaticQuery} from "gatsby";
import Image from "gatsby-image";
import {BackgroundImage, ButtonWrapper, Container, StyledTitleXL, SubTitle, TitleWrapper} from "./styles";
import {useTheme} from "styled-components";
import {PrimaryButton} from "components/button/Button";
import {SectionWrapper} from "../../shared/Styles/Wrappers";

const Hero = () => {
  const query = useStaticQuery(graphql`
      query {
          hero: file(relativePath: { eq: "img/hero.jpg" }) {
              childImageSharp {
                  fixed(width: 1920, quality:100) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
              }
          }
      }
  `);

  const theme = useTheme();

  return (
    <GlobalWrapper id="hero">
      <Container>
        <BackgroundImage>
          <Image
            style={{width: "100%", height: "100%"}}
            imgStyle={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            fixed={query.hero.childImageSharp.fixed}
          />
        </BackgroundImage>
        <SectionWrapper style={{zIndex: "2"}}>
          <TitleWrapper>
            <SubTitle weight={theme.weights.less_bold} size="40px">
              {data.subTitle}
            </SubTitle>
            <StyledTitleXL
              weight={theme.weights.normal}
              color={theme.colors.white}
            >
              {data.title}
            </StyledTitleXL>
            <ButtonWrapper>
              <PrimaryButton type={data.buttonType} to={data.slug}>
                {data.button}
              </PrimaryButton>
            </ButtonWrapper>
          </TitleWrapper>
        </SectionWrapper>
      </Container>
    </GlobalWrapper>
  );
};

export default Hero;
