import styled from "styled-components";
import {Paragraph, TitleL} from "../typography/Typography";

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: ${({swap}) => (swap ? "0" : "60px")};
    padding-left: ${({swap}) => (swap ? "60px" : "0")};
    align-items: flex-start;
    justify-content: center;

    h2 {
        max-width: 400px;
        margin-top:
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        align-items: center;
        text-align: center;
        padding: 0;
    }

    & > * {
        margin: 20px 0;

        @media screen and (max-width: ${({theme}) =>
                theme.breakpoints.laptop}) {
            margin: 14px 0;
        }

        @media screen and (max-width: ${({theme}) =>
                theme.breakpoints.tablet_big}) {
            margin: 10px 0;
        }

        @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
            margin: 12px 0;
        }
    }
`;

export const IconWrap = styled.div`
    height: 63px;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop}) {
        height: 50px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        height: 32px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet}) {
        height: 28px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_small}) {
        height: 42px;
    }
`;

export const Icon = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const StyledParagraph = styled(Paragraph)`
    max-width: 490px;
    color: ${({theme}) => theme.colors.white};

    span {
        font-weight: ${({theme}) => theme.weights.bold};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        max-width: 290px;
    }
`;

export const StyledTitle = styled(TitleL)`
    color: ${({theme}) => theme.colors.white};
    line-height: 1.2;
    font-weight: ${({theme}) => theme.weights.light};

    span {
        display: block;
        font-weight: ${({theme}) => theme.weights.less_bold};
        font-family: "Rajdhani", sans-serif;

        @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_small}) {
            &:first-child {
                display: inline-block;
            }
        }
    }
`;

export const ButtonWrap = styled.div`
    margin-top: 48px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        margin-top: 24px;
    }
`;
