import styled from "styled-components";
import {GlobalWrapper} from "shared/Styles/Wrappers";

export const Container = styled(GlobalWrapper)`
    padding: 124px 0;
    background-color: ${({theme}) => theme.colors.black};

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding: 96px 0;
    }
    
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_big}) {
        padding: 48px 0;
    }
`;

export const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-top: 72px;
    margin: 0 auto;
    justify-content: center;
    max-width: 1290px;
    width: 100%;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        max-width: 961px;
        padding-top: 54px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        max-width: 714px;
        padding-top: 42px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        max-width: 562px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        max-width: 320px;
    }
`;
