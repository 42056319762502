import styled from "styled-components";
import {Paragraph, TitleM} from "../../../components/typography/Typography";

export const CardBody = styled.a`
    padding: 90px 0 72px;
    background-color: ${({theme}) => theme.colors.section_bg};
    width: calc(33.33% - 14px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background-color 0.1s;
    cursor: pointer;

    &:nth-child(2n) {
        margin: 0 20px;
    }

    &:hover {
        background-color: ${({theme}) => theme.colors.primary};
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop}) {
        padding: 48px 0 54px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 100%;
        margin-bottom: 24px;
        flex-direction: row;
        align-items: center;
        padding: 48px;

        &:nth-child(2n) {
            margin: 0 0 24px 0;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        width: 100%;
        flex-direction: column;
        padding: 48px 24px;

        &:hover {
            background-color: ${({theme}) => theme.colors.section_bg};
        }
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        flex-direction: row;
        justify-content: flex-start;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        flex-direction: column;
    }
`;

export const IconWrapper = styled.div`
    width: 72px;
    margin-bottom: 36px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 54px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 42px;
        margin-bottom: 24px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-bottom: 0;
        width: 32px;
        height: 32px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        margin-bottom: 24px;
    }
`;

export const Icon = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const CardTitle = styled(TitleM)`
    line-height: 1.3;
    color: ${({theme}) => theme.colors.white};
    transition: color 0.1s;

    ${CardBody}:hover & {
        color: ${({theme}) => theme.colors.black};
    }

    span {
        display: block;
        font-family: "Rajdhani", sans-serif;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        text-align: left;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding-left: 24px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        padding-left: 0;
        text-align: center;
        margin-bottom: 12px;

        ${CardBody}:hover & {
            color: ${({theme}) => theme.colors.white};
        }

        span {
            display: inline;
        }
    }
`;

export const StyledParagraph = styled(Paragraph)`
    color: ${({theme}) => theme.colors.white};
    transition: color 0.1s;
    text-align: center;
    max-width: 70%;
    margin: 24px auto 0;

    ${CardBody}:hover & {
        color: ${({theme}) => theme.colors.black};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        text-align: left;
        max-width: 50%;
        margin-top: 0;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        max-width: 100%;
        text-align: center;

        ${CardBody}:hover & {
            color: ${({theme}) => theme.colors.white};
        }
    }
`;
