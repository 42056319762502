import React from "react";
import {BenefitBody, BenefitDesc, BenefitTitle, Img, ImgWrapper,} from "./styles";

const BenefitsItem = ({benefit}) => (
  <BenefitBody bg={benefit.color}>
    <ImgWrapper>
      <Img src={benefit.icon} alt=""/>
    </ImgWrapper>
    <BenefitTitle>{benefit.title}</BenefitTitle>
    <BenefitDesc>
      {benefit.text}
    </BenefitDesc>
  </BenefitBody>
);

export default BenefitsItem;
