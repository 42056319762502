import React from "react";
import {BackgroundImage, ButtonWrapper, Container, Content, StyledTitle, TitleWrapper} from "./styles";
import Image from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";
import {SectionWrapper} from "shared/Styles/Wrappers";
import {useTheme} from "styled-components";
import data from "data/Baner.js";
import {PrimaryButton} from "../../components/button/Button";

const Baner = () => {
  const theme = useTheme();
  const query = useStaticQuery(graphql`
      query {
          baner: file(relativePath: { eq: "img/baner.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);

  return (
    <Container>
      <BackgroundImage>
        <Image
          style={{width: "100%", height: "100%"}}
          imgStyle={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          fluid={query.baner.childImageSharp.fluid}
        />
      </BackgroundImage>
      <SectionWrapper>
        <Content>
          <TitleWrapper>
            {data.icon}
            <StyledTitle color={theme.colors.white} size="30px" center>
              {data.title}
            </StyledTitle>
          </TitleWrapper>
          <ButtonWrapper>
            <PrimaryButton type={data.buttonType}>{data.button}</PrimaryButton>
          </ButtonWrapper>
        </Content>
      </SectionWrapper>
    </Container>
  );
};

export default Baner;
