import React from "react";
import Discord from "assets/img/svg/discord.svg";
import DiscordGold from "assets/img/svg/discord-gold.svg";
import Training from "assets/img/svg/training.svg";
import TrainingGold from "assets/img/svg/training-gold.svg";
import Assistance from "assets/img/svg/assistpro.svg";
import AssistanceGold from "assets/img/svg/assistpro-gold.svg";

const data = [
  {
    title: (
      <>
        Locked Trade <span>Discord</span>
      </>
    ),
    icon: DiscordGold,
    hover: Discord,
    text: "Poznaj społeczność, która dzieli się wiedzą i informacjami na temat  różnych rynków."
  },
  {
    title: (
      <>
        Szkolenia <span>Rynek Akcji</span>
      </>
    ),
    icon: TrainingGold,
    hover: Training,
    text: "Zdobądź wiedzę potrzebną do inwestowania na amerykańskim rynku akcji."
  },
  {
    title: (
      <>
        Wirtualny <span>asystent Pro</span>
      </>
    ),
    icon: AssistanceGold,
    hover: Assistance,
    text: "Sprawdź narzędzie, które wykrywa  zmiany trendów na różnych rynkach oraz walorach."
  },
];

export default data;
