import React from "react";
import {useTheme, withTheme} from "styled-components";
import {ButtonWrap, FlexContainer, Icon, IconWrap, StyledParagraph, StyledTitle} from "./styles";
import {Paragraph, TitleL} from "components/typography/Typography";
import {PrimaryButton} from "components/button/Button";

const TextBox = ({data, swap}) => {
  const theme = useTheme();

  return (
    <FlexContainer swap={swap}>
      {
        data.icon && (
          <IconWrap>
            <Icon src={data.icon} alt="icon"></Icon>
          </IconWrap>
        )
      }
      {
        data.title_white && (
          <TitleL weight={theme.weights.normal} color={theme.colors.white}>
            {data.title_white}
          </TitleL>
        )
      }
      <StyledTitle>{data.title}</StyledTitle>
      <StyledParagraph color={theme.colors.white}>
        {data.text1}
      </StyledParagraph>
      {
        data.textbold && (
          <Paragraph
            weight={theme.weights.less_bold}
            color={theme.colors.white}
          >
            {data.textbold}
          </Paragraph>
        )
      }
      <ButtonWrap>
        <PrimaryButton type="right_arrow" to={data.slug} anchor>
          {data.buttonText}
        </PrimaryButton>
      </ButtonWrap>
    </FlexContainer>
  );
}

export default withTheme(TextBox);
