import styled from "styled-components";
import {TitleM, TitleXL} from "components/typography/Typography";
import {PageTitle} from "../../components/typography/Typography";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 100vh;
    max-height: -webkit-fill-available;
    padding-top: 80px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        min-height: calc(100vh - 56px);
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${({theme}) => theme.colors.black};
        opacity: 0.2;
        z-index: 1;
    }
`;

export const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
`;

export const TitleWrapper = styled.div`
    width: 50%;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        margin-left: auto;
        margin-right: auto;
        padding: 0 12px;
        width: 100%;
        margin-top: 36px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone}) {
        padding: 0;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet}) and (orientation: landscape) {
        margin-top: 0;

        a {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
`;

export const SubTitle = styled(PageTitle)`
    z-index: 100;
    color: ${({theme}) => theme.colors.white};
    line-height: 1.15;
    margin-bottom: 16px;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        text-align: center;
        margin-bottom: 12px;
        padding: 0 24px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet}) and (orientation: landscape) {
        text-align: left;
        padding-right: 0;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone}) {
        padding: 0;
    }
`;

export const StyledTitleXL = styled(TitleXL)`
    margin-bottom: 40px;
    font-weight: ${({theme}) => theme.weights.bold};
    text-transform: uppercase;

    span {
        font-weight: ${({theme}) => theme.weights.bold};
        display: block;
        font-family: "Rajdhani", sans-serif;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        text-align: center;
        padding: 0 24px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet}) and (orientation: landscape) {
        font-size: 20px;
        margin-bottom: 24px;
        padding-right: 0;
        text-align: left;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone}) {
        padding: 0;
    }
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        justify-content: center;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small})  and (orientation: landscape) {
        justify-content: flex-start;
        padding-left: 24px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled(TitleM)`
    font-size: 12px;
    color: ${({theme}) => theme.colors.white};
    margin-top: 18px;
    text-align: center;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet}) {
        font-size: 10px;
        margin-top: 10px;
    }
`;

