import React, {useState} from "react";
import {CardBody, CardTitle, ContentWrapper, Icon, IconWrapper, StyledParagraph,} from "./styles";

const Card = ({card}) => {
  const [img, setImg] = useState(card.icon);

  const handleHover = () => {
    if (window.innerWidth > 1100) {
      setImg(card.hover);
    }
  }

  const handleMouseOut = () => {
    setImg(card.icon);
  }

  return (
    <CardBody onMouseOver={handleHover} onMouseOut={handleMouseOut}>
      <ContentWrapper>
        <IconWrapper>
          <Icon src={img} alt=""/>
        </IconWrapper>
        <CardTitle
          center
        >
          {card.title}
        </CardTitle>
      </ContentWrapper>
      <StyledParagraph>
        {card.text}
      </StyledParagraph>
    </CardBody>
  );
};

export default Card;
