import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

export const useImage = () => {
  const query = useStaticQuery(graphql`
      query {
          aboutus: file(relativePath: { eq: "img/about.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `);
  return query;
};

export const useData = () => {
  const query = useImage();

  const data = {
    title: <><span>Współpraca</span> z LOCKED TRADE jest dla Ciebie</>,
    text1: <><span>Locked Trade</span> to marka, której celem jest pomaganie klientom, by stali się jak najlepszymi inwestorami. Doświadczony zespół stale udoskonala narzędzia pracy oraz zbiera dodatkowe materiały, aby inwestowanie na wielu rynkach było jak najbardziej skuteczne<br/><br/>
      Jednym z takich narzędzi jest <span>Wirtualny Asystent!</span></>,
    buttonText: "Sprawdź",
    image: <Img fluid={query.aboutus.childImageSharp.fluid}/>,
    id: "about",
    slug: "#about",
    bg: "dark",
  };
  return data;
};

export default useData;
