import styled from "styled-components";

export const TitleXL = styled.h2`
    color: ${({color}) => (color ? color : ({theme}) => theme.colors.black)};
    font-size: 100px;
    font-weight: ${({weight}) =>
            weight ? weight : ({theme}) => theme.weights.less_bold};
    text-align: ${({center}) => (center ? "center" : "")};

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 76px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        font-size: 56px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 44px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 32px;
    }
`;
export const TitleL = styled.h2`
    color: ${({color}) => (color ? color : ({theme}) => theme.colors.white)};
    font-size: ${({size}) => (size ? size : ({theme}) => theme.fonts.l)};
    font-weight: ${({weight}) =>
            weight ? weight : ({theme}) => theme.weights.bold};
    text-align: ${({center}) => (center ? "center" : "")};

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 30px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 23px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 17px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 20px;
    }
`;
export const TitleM = styled.h3`
    color: ${({color}) => (color ? color : ({theme}) => theme.colors.black)};
    font-size: ${({size}) => (size ? size : ({theme}) => theme.fonts.m)};
    font-weight: ${({weight}) =>
            weight ? weight : ({theme}) => theme.weights.less_bold};
    text-align: ${({center}) => (center ? "center" : "")};
    
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 22px;
    }
    
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 18px;
    }
    
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 16px;
    }
`;
export const Paragraph = styled.p`
    color: ${({color}) => (color ? color : ({theme}) => theme.colors.black)};
    font-size: ${({size}) => (size ? size : ({theme}) => theme.fonts.xs)};
    font-weight: ${({weight}) =>
            weight ? weight : ({theme}) => theme.weights.normal};
    text-align: ${({center}) => (center ? "center" : "")};
    line-height: 1.6;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 12px;
    }
`;

export const PageTitle = styled.h1`
    color: ${({color}) => (color ? color : ({theme}) => theme.colors.black)};
    font-size: ${({size}) => (size ? size : ({theme}) => theme.fonts.s)};
    font-weight: ${({weight}) => (weight ? weight : "")};
    text-align: ${({center}) => (center ? "center" : "")};

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 30px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 18px;
    }
`;

