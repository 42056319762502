const data = {
  subTitle: "Inwestuj z Wirtualnym Asystentem",
  title: "Locked Trade",
  button: "Sprawdź",
  buttonType: "right_arrow",
  slug: "about",
  id: "hero",
};

export default data;
