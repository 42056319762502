import React from "react";
import {AbsoluteSectionWrapper, BigWrapper, Column, GlobalWrapperBg, Image, ImageBg,} from "./styles";
import TextBox from "components/textBox/TextBox";

const TextImage = ({data, swap, reverse}) => (
  <GlobalWrapperBg id={data.id}>
    <BigWrapper swap={swap} reverse={reverse}>
      <AbsoluteSectionWrapper swap={swap}>
        <Column>
          <TextBox swap={swap} data={data}></TextBox>
        </Column>
      </AbsoluteSectionWrapper>
      <Image swap={swap}>
        <ImageBg swap={swap}/>
        {data.image}
      </Image>
    </BigWrapper>
  </GlobalWrapperBg>
);

export default TextImage;
