import React from "react";

import {Icon, IconWrapper} from "sections/Baner/styles.js";
import Handshake from "assets/img/svg/handshake.svg";

const data = {
  title: <>Skontaktuj się z nami i <span>zacznij inwestować już dziś</span></>,
  buttonType: "right_arrow dark",
  button: "Sprawdź",
  icon: (
    <>
      <IconWrapper>
        <Icon src={Handshake} alt=""/>
      </IconWrapper>
    </>
  ),
};

export default data;
