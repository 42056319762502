import styled from "styled-components";
import {GlobalWrapper, SectionWrapper} from "shared/Styles/Wrappers";
import Bg from "assets/img/svg/section-bg.svg";

export const ImageBg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${Bg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    transform: ${({swap}) => swap ? "translateX(100%)" : "translateX(-100%)"};
    width: 100%;
    height: 100%;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        display: none;
    }
`;

export const GlobalWrapperBg = styled(GlobalWrapper)`
    background: ${({theme}) => theme.colors.section_bg};
    margin: -1px 0;
`;

export const BigWrapper = styled.div`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: ${({swap}) => (swap ? "flex-start" : "flex-end")};

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        flex-direction: ${({swap}) => (swap ? "column-reverse" : "column")};
        flex-direction: ${({reverse}) => (reverse ? "column-reverse" : "")};;
    }
`;

export const AbsoluteSectionWrapper = styled(SectionWrapper)`
    position: absolute;
    top: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: ${({swap}) => (swap ? "row-reverse" : "row")};
    align-items: center;
    z-index: 2;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        position: relative;
        justify-content: center;
        max-width: 1000px;
        padding: 64px 24px ;
    }
`;

export const Column = styled.div`
    width: 50%;
    display: flex;
    align-items: center;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        width: 100%;
        justify-content: center;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        width: 100%;
    }
`;

export const Image = styled.div`
    position: relative;
    width: 50%;
    height: 100%;

    .gatsby-image-wrapper {
        max-width: 100% !important;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${({theme}) => theme.colors.black};
        opacity: 0.2;
        z-index: 2;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        width: 100%;
    }
`;
