import React from "react";
import Document from "assets/img/svg/document.svg";
import Help from "assets/img/svg/help.svg";
import Chat from "assets/img/svg/chat.svg";

export const sectionData = {
  title: <><span>Korzyści</span> wirtualnego asystena</>
};

export const benefitsData = [
  {
    icon: Document,
    title: "Obserwacja 7 instrumentów",
    text: "Asystent stale bada i monitoruje kilka instrumentów jednocześnie, co umożliwia ciągły dostęp do wielu rynków."
  },
  {
    icon: Chat,
    title: "Wykrywanie zmiany trendu",
    text: "Narzędzie wykrywa zmiany trendów na różnych rynkach oraz walorach, dzięki czemu otrzymujesz pomocne w inwestowaniu informacje."
  },
  {
    icon: Help,
    title: "System powiadomień",
    text: "Aplikacja posiada algorytm, który znajduje potencjalne miejsca zajęcia pozycji, a następnie wysyła powiadomienia o wygenerowanym sygnale."
  },
];
