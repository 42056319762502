import React from "react";
import {BenefitsContainer, Container, StyledTitle, TitleWrapper,} from "./styles";
import {SectionWrapper} from "shared/Styles/Wrappers";
import {benefitsData, sectionData} from "data/Benefits";
import BenefitsItem from "./BenefitsItem/BenefitsItem";
import uuid from "react-uuid";

const Benefits = ({id}) => (
  <Container id={id}>
    <SectionWrapper>
      <TitleWrapper>
        <StyledTitle center>
          {sectionData.title}
        </StyledTitle>
      </TitleWrapper>
      <BenefitsContainer>
        {
          benefitsData.map((el) => (
            <BenefitsItem benefit={el} key={uuid()}/>
          ))
        }
      </BenefitsContainer>
    </SectionWrapper>
  </Container>
);

export default Benefits;
