import React from "react";
import {TitleL} from "components/typography/Typography";
import {CardsContainer, Container} from "./styles";
import cards from "data/Cards";
import Card from "./Card/Card";
import uuid from "react-uuid";

const Cards = ({id}) => (
  <Container id={id}>
    <TitleL center>Nasze produkty</TitleL>
    <CardsContainer>
      {
        cards.map((card) => (
          <Card card={card} key={uuid()}/>
        ))
      }
    </CardsContainer>
  </Container>
);

export default Cards;
